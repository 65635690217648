import axios from 'axios'
import { getHTTPURL } from '../utils/envUtil'

export async function recordLive(pid: string, streamID: string, robotID: number): Promise<void> {
  const url: string = getHTTPURL() + '/api/recordLive'

  const result = await axios.post(url, {
    pid,
    streamID,
    robotID
  })
  const data = result.data
  if (!data.success) {
    console.error('record Live error', JSON.stringify(data))
  } else {
    console.log('record Live success', JSON.stringify(data))
  }
}

export async function getRobots(
  userID: number
): Promise<
  { avatar: string; listenVideo: string; waitVideo: string; speakVideo: string; id: number }[]
> {
  const url: string = getHTTPURL() + '/api/getRobots'

  const result = await axios.post(url, {
    userID
  })
  const data = result.data
  if (!data.robots || data.robots.length < 1) {
    console.error('getRobots  error', JSON.stringify(data))
    return []
  } else {
    return data.robots
  }
}

export async function setCurrentRobot(
  robotID: number,
  count: string
): Promise<{ sayHi: string; appKey: string; maxSilence: number }> {
  const url: string = getHTTPURL() + '/api/setCurrentRobot'
  const result = await axios.post(url, {
    robotID,
    count
  })
  return result.data
}
